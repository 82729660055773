import {createRouter, createWebHistory} from "vue-router";

const Login = () => import("@/views/LoginPage");
const Template = () => import("@/views/TemplateSelection")
const Mode = () => import("@/views/ModeSelection")
const WorkSpace = () => import("@/views/MainWorkspace")

const MoreIdea = () => import("@/views/MoreIdea")
const Waterfall = () => import("@/components/WaterfallPage.vue")
const Usage = () => import("@/components/UsagePage.vue")

const FullResearch = () => import("@/views/FullReasearch.vue")
const FullResearchDisplay = () => import("@/components/FullResearchDisplay.vue")

const routes = [
    {path: '/', redirect: '/login'},
    {path: '/login', component: Login},
    {path: '/template', component: Template},
    {path: '/mode', component: Mode},
    {path: '/full/research', component: FullResearch},
    {path: '/more-idea', component: MoreIdea},
    {
        path: '/workspace',
        component: WorkSpace,
        children: [
            {
                path: 'waterfall',
                component: Waterfall
            },
            {
                path: 'usage',
                component: Usage
            },
            {
                path: 'full/research',
                component: FullResearchDisplay
            }
        ]
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes
})
export default router;
