export default {
    global: {
        projectType:{
            type3:'《新品上市前策》',
            type4:'《社交口碑行銷》',
            type6:'《整合行銷創意》'
        },
        params:{
            param1:'產品Slogan與Slogan詮釋',
            param2:'傳播口號與傳播口號創意說明',
            param3:'傳播主題Theme',
            param4:'核心創意概述',
        },
        successMsg: '恭喜你，成功完成！',
        loginFailed: '登錄異常，請聯繫客服。',
        errorMsg: 'Oops，出現了錯誤。',
        InsufficientBalance:'額度不足，請充值',
        concurrentError:{
            title:'許可權不足',
            content:'目前有任務在進行中，如您想同時讓艾迪處理多個任務，歡迎聯繫商務升級為超級會員',
            ok:'我知道了'
        }
    },
    views: {
        LoginPage: {
            title: '歡迎來到',
            username: '請輸入帳號',
            password: '請輸入密碼',
            login: '登錄',
            qrcode:{
                title:'線上客服'
            }
        },
        TemplateSelection: {
            pageDesc: '你的創意搭檔',
            template1: {
                title: '01 新品上市前策',
                desc: '為新品上市推廣，推導完成傳播策略分析與傳播口號創意。',
                dialog: {
                    suitableScene: '全新產品上市、創作產品傳播口號、需要前端傳播策略推導',
                    noIdea: {
                        output1: '人群洞察創意標籤',
                        output2: '新品傳播口號創意',
                    },
                    hasIdea: {
                        output1: '市場分析',
                        output2: '競品分析',
                        output3: '產品賣點分析',
                        output4: '人群分析',
                        output5: '制勝傳播策略',
                        output6: '新品傳播定位',
                        output7: '新品傳播口號'
                    }
                }
            },
            template2: {
                title: '02 社交口碑行銷',
                desc: '為產品的社交平臺推廣，推導完成社交心智、傳播主題與核心創意。',
                dialog: {
                    suitableScene: '產品/品牌/事件、社交平臺推廣、產出社交口碑心智',
                    noIdea: {
                        output1: '人群洞察',
                        output2: '傳播主題與核心創意',
                    },
                    hasIdea: {
                        output1: '社交口碑心智',
                        output2: '定義差異化核心價值',
                        output3: '人群分析',
                        output4: '人群洞察創意標籤',
                        output5: '制勝心智策略',
                        output6: '傳播主題與核心創意',
                        output7: '創意文案片語',
                    },
                    notice:'注意：方案不含市場分析、競品分析與產品賣點分析，若需要請選擇“新品上市前策”。'
                }
            },
            template3: {
                title: '03 整合行銷創意',
                desc: '為產品的社交平臺推廣，打造社交熱點事件，完成整合行銷創意方案。',
                dialog: {
                    suitableScene: '產品/品牌/事件、社交平臺推廣、產出整合行銷全案創意',
                    noIdea: {
                        output1: '人群洞察',
                        output2: '傳播主題與核心創意',
                        output3: 'Buzz Point 熱點事件創意',
                    },
                    hasIdea: {
                        output1: '人群洞察',
                        output2: '傳播主題與核心創意',
                        output3: 'Buzz Point 熱點事件',
                        output4: 'Teaser 階段創意與話題',
                        output5: 'Launch 階段創意與話題',
                        output6: 'Sustain 階段創意與話題',
                        output7: '擴散/ 轉化/ 平臺選擇',
                        output8: 'KOL 破圈話題創意',
                        output9: 'KOL 量級推薦與話題創意',
                        output10: 'KOL 投放矩陣',
                    }
                }
            },
            template4: {
                title:'04 市場情報官',
                desc:'快速擬定市場策略、瞭解競爭品牌、洞察消費者、分析產品賣點、分析行銷需求。',
                dialog:{
                    suitableScene:'快速擬定市場策略、瞭解競爭品牌、洞察消費者、分析產品賣點、分析行銷需求。',
                    outputs:{
                        output1:'市場競爭情況報告',
                        output2:'競品品牌對比報告',
                        output3:'本品優勢賣點和消費者評價',
                        output4:'目標人群的產品洞察報告',
                        output5:'行業媒介投放情況報告',
                        output6:'競品媒介投放情況報告',
                        output7:'競品傳播策略分析報告'
                    }
                }
            },
            modal: {
                suitableScene: '適合場景：',
                noIdea: 'Brainstorming模式輸出',
                hasIdea: '完整方案輸出',
                output: '輸出',
                ok: '我知道了'
            },
            recentProject: {
                title: '你最近的方案',
                mode: {
                    noIdea: 'Brainstorming模式',
                    hasIdea: '完整方案模式',
                    research: '情報調研'
                }
            },
            announcement:{
                title:'ADGO新功能',
                content:'<div>1.瘋狂創意模式：我們在brainstorming功能下加入了“瘋狂創意模式”，只需要打開“瘋狂模式”按鈕，你將在brainstorming創意中獲得比從前更具有腦洞的創意，當然你也可以關閉它來獲取更符合本次任務邏輯的創意</div>' +
                    '<div>2.市場情報官板塊：很高興向您推出該功能。在該板塊下，您只需要輸入您想瞭解的任何關於產品，市場，人群等一系列的市場情報資訊。相較於在Brief中的市場簡報，您可以在“市場情報官”板塊中獲得更全面，更多資訊維度，更深入的市場情報</div>',
                ok:'我知道了'
            }
        },
        ModeSelection: {
            button: {
                return: '返回',
                generate: '生成Brief',
                research: '開始調研'
            },
            pageDesc: '今天你想創造什麼？',
            noIdea: 'Brainstorming模式',
            hasIdea: '完整方案模式',
            giveWhat:{
                idea:"很多超棒的IDEA",
                plan:"超完整的思路和方案"
            },
            label1:'本次行銷任務（必填）：',
            label2:'傳播主題Theme（選填，若不填寫 ADGO 將為你想出來）：',
            label3:'核心創意概述（選填，若不填寫 ADGO 將為你想出來）：',
            label4:'傳播主題文案（選填，若不填寫 ADGO 將為你想出來）：',
            label5:'傳播主題創意概念说明（選填，若不填寫 ADGO 將為你想出來）：',
            label6:'瘋狂模式：',
            tooltip6:'打開它，你會獲得許多異想天開的創意',
            placeholder1: '請告訴艾迪這次新品上市的行銷任務的需求，可能包括但不限於以下資訊：品牌名/ 產品或活動名/ 產品或活動的賣點描述/ 目標人群基礎資料/ 競爭品牌的產品或活動/ 競爭品牌的產品或活動的賣點描述/ 指定的媒體平台和媒體玩法/ 本次傳播的目的或期望的效果/ 指定的名人明星、合作IP或異業合作。\n\n如需要參考，你也可以點擊下方的範例。',
            placeholder2: '請輸入已經完成的傳播主題創意，可能包括但不限於：傳播主題文案/ 傳播主題創意說明。接下來ADGo將會基於你提供的傳播主題，繼續完成全部方案。\n\n如需要參考，你也可以點擊下方的範例。',
            placeholder3: '請輸入已經完成的主創意，可能包括但不限於：創意概念/ 創意手法/ 媒體創意/ 創意內容/ 等具體執行創意。接下來ADGo將會基於你提供的主創意描述，繼續完成全部方案。\n\n如需要參考，你也可以點擊下方的範例。',
            placeholder4: '請輸入已經完成的傳播主題文案，可能包括但不限於：傳播主題文案/ Slogan。接下來ADGo將會基於你提供的傳播主題文案，繼續完成全部方案。\n\n如需要參考，你也可以點擊下方的範例。',
            placeholder5: '請輸入傳播主題文案的創意概念說明，可能包括但不限於：創意概念/ 創意洞察/ 文案手法/ 創意來源/ 為何打動人/ 等文案創意的詳細介紹。接下來ADGo將會基於你提供的傳播主題創意，繼續完成全部方案。\n\n如需要參考，你也可以點擊下方的範例。',
            example: {
                title: '參考範例',
            },
            example1:{
                title:'範例一',
                brief:'Under armour即將在2024年上新一款鞋slipspeed，它的核心賣點是鞋後跟可以折疊，折疊起來日常休閒可當拖鞋使用，鞋跟立起可以作為專業的訓練鞋在運動中使用，品牌想借此新品上市，在推廣產品的同時，突破品牌在消費者心中硬核專業的形象，拓展更多圈層人群，此鞋款受眾18-30歲，主要競品產品：Nike Metcon 9、Reebok Nano X4。',
                spreadTheme:'脚下解锁，生活无限可能\n',
                coreIdea:'此创意源自现代都市生活的多面性，结合SlipSpeed可折叠后跟的独特设计，创造出"解锁"这一富有想象力的概念。它不仅暗示了产品的变形功能，更寓意着解锁生活中的无限可能。这一主题迎合了年轻人追求效率与多元化的需求，引发他们对简化生活、提升品质的共鸣。同时，巧妙运用了当下流行的"解锁新姿势"社交热梗，激发用户的好奇心和参与欲，符合"新奇有趣"的社交动机。\n'
            },
            example2:{
                title:'範例二',
                brief:'雀巢阿拉比卡咖啡品種「Star 4」，強調低碳排放和供應鏈優化，特別吸引環保意識強烈和注重品質的咖啡愛好者，精準鎖定台灣市場中注重生活品質和環境永續的都市人士。這款咖啡不僅具有獨特風味，還特別強調其對應氣候變遷的創新性，適合日常飲用和特殊場合品嚐。對標其他精品咖啡品牌，強調雀巢在環保創新、品質控制以及全球供應鏈優化上的優勢。',
                spreadTheme:'「一杯咖啡，一份責任」\n' ,
                coreIdea:'在這個對環保意識日益重視的時代，雀巢Star 4不僅滿足了顧客對咖啡口感的苛刻要求，更滿足了他們對環保的道德期待。每一杯Star 4，都是對生活品質與環境保護雙重承諾的展示，它不只是咖啡，更是一份對未來的責任。\n'
            },
            example3:{
                title:'範例一',
                brief:'全家便利商店的「開學季優惠」活動 活動內容： 全家便利商店在Dcard上針對大學生舉辦了「開學季優惠」活動，發布各種折扣和優惠資訊，同時在Dcard上進行問答互動，解答學生的各種問題。 目標明確：精準鎖定大學生群體，活動設計符合他們的需求和喜好。 平臺互動：利用Dcard的社交互動功能，增加用戶參與度和品牌好感度。 實用性強：提供實際的折扣和優惠，直接提升門店銷量。競爭品牌：7-ELEVEN、萊爾富、全家 FamilyMart 的開學季活動。',
                spreadTheme:'「全家の深夜食堂：熬夜學霸篇」\n' +
                    '創意描述：全家便利商店與日本知名漫畫IP《深夜食堂》合作，推出「全家の深夜食堂：熬夜學霸篇」特別企劃。我們將《深夜食堂》溫馨治癒的風格與台灣學生的熬夜學習生活相結合，創造出一系列溫暖人心的漫畫短篇和動畫。\n',
                coreIdea:'' +
                    '主要內容包括：漫畫連載：邀請《深夜食堂》原作者安倍夜郎創作以台灣大學生為主角的特別篇，每週在全家APP上更新一話，講述學生們在全家便利商店找到溫暖和力量的故事。 互動式動畫：根據漫畫內容製作短篇動畫，讓用戶可以在特定情節做出選擇，體驗不同的故事發展。完成互動的用戶可獲得限定版「全家の深夜食堂」周邊商品。 實體主題店：在全台大學周邊的指定全家門市打造「深夜食堂」主題專區，不僅裝潢風格模仿漫畫場景，還提供漫畫中出現的特色餐點，讓學生們體驗漫畫中的溫暖氛圍。 通過這個IP合作，我們不僅能吸引《深夜食堂》的原有粉絲，還能讓更多學生感受到全家便利商店如同漫畫中的「深夜食堂」一般，是他們熬夜學習時溫暖可靠的陪伴。\n'
            },
            example4:{
                title:'範例二',
                brief:'X-TRAIL新產品推廣活動 活動內容：X-TRAIL針對已婚且有小孩的40-54歲男性，喜愛戶外活動如三鐵、路跑和露營的消費群體，推出新車型，強調其寬敞舒適的車內空間，適合全家出遊。在Facebook和Instagram上分享戶外旅行規劃和家庭回憶的故事，鼓勵用戶分享他們的家庭旅行照片。目標明確：精準鎖定喜歡戶外活動且經常載家人出遊的中年男性，確保產品設計符合他們的生活方式和需求。平台互動：利用社交媒體的故事功能，增加用戶參與度和品牌情感連結。實用性強：突出產品的空間舒適性和多功能性，提升購車意願。競爭品牌：Toyota RAV4、Honda CR-V 的家庭戶外活動推廣活動。',
                spreadTheme:'“開啟美好，X-Trail與你同行”\n' +
                    '傳播主題創意說明\n' +
                    '本次傳播主題“開啟美好，X-Trail與你同行”源自於目標人群對於家庭時間的重視及對於品質生活的追求。結合Nissan X-Trail的產品特性，如寬敞的空間和安全技術，創造一個讓消費者能感受到與家人共度時光的重要性和美好的獨特觀點。\n\n',
                coreIdea:'Nissan X-Trail推出創新的線上到線下整合行銷活動“X-Trail家庭探險計劃”，旨在提升家庭之間的互動與產品體驗。此活動包含一個互動式的網站平台，家庭成員可以在此規劃他們的旅行路線，並根據旅行地點獲得定制的安全駕駛指南和旅遊小貼士。\n'
            },
            example5:{
                title:'範例三',
                brief:'臺灣肯德基在 2024 推出四年前的人氣限定款“大阪無骨脆雞”產品系列，賣點是日式風味照燒醬汁搭配無骨脆雞以及炒麵，目標對象是喜好日本文化與日式口味的年輕消費者，以及喜愛嚐鮮的美食愛好者，需要推薦臺灣明星藝人代言/ 並在臺灣的社交平臺推廣。競爭品牌：麥當勞、摩斯漢堡的日式限定美食推廣活動。',
                spreadTheme:'「味蕾旅行，日式風情一脆難求」\n' +
                    '傳播主題創意說明：\n' +
                    '這個主題利用了「味蕾旅行」這一社交熱梗，符合年輕人對新奇與探索的心理需求。結合「大阪無骨脆雞」的日式照燒風味，這個創意不僅刻畫出品嘗此菜品就如同進行一場味覺上的日本之旅，同時也挑戰消費者去尋找與品嘗這種獨特的風味。\n',
                coreIdea:'肯德基台灣聯手台灣最熱門的音樂串流平台KKBOX，推出「味蕾音樂節」。透過KKBOX平台推出特別策劃的「大阪無骨脆雞音樂單曲」，每首歌曲都與一款特定口味的無骨脆雞相關聯，讓消費者在品嚐美食的同時，享受定制的音樂體驗。此外，肯德基將在KKBOX上舉辦線上音樂節，邀請台灣本土歌手演出，並在活動期間內提供獨家優惠券，讓用戶點購大阪無骨脆雞。用戶可以通過參與線上互動，如分享歌單、發表美食評論等，贏取獨家限量周邊商品。\n'
            }
        },
        Workspace: {
            copyright: '艾思智創出品',
            menu: {
                team: '團隊',
                usage: '用量情況',
                settings: '設置',
                exit: '退出登錄'
            },
            modal:{
                update:{
                    title:'修改brief名稱',
                    placeholder:'請輸入brief名稱'
                },
                delete:{
                    message1:'此操作將永久刪除brief，',
                    message2:'是否繼續？'
                },
                settings:{
                    title:'設置',
                    tab1:{
                        title:'通用',
                        label1:{
                            word1:'用',
                            word2:'戶',
                            word3:'名:',
                        },
                        label2:{
                            word1:'郵',
                            word2:'箱:',
                        },
                        label3:{
                            word1:'語',
                            word2:'言',
                            word3:'環',
                            word4:'境:',
                        },
                        select:{
                            option1:'簡體中文',
                            option2:'繁體中文',
                        },
                        label4:{
                            word1:'團',
                            word2:'隊',
                            word3:'成',
                            word4:'員:',
                        },
                        label5:'刪除所有聊天',
                        button5:'刪除全部',
                        label6:'修改密碼',
                        button6:'點擊修改'
                    },
                    tab2:{
                        title:'搜索偏好',
                        label1:{
                            word1:'地',
                            word2:'區',
                        },
                        label2:{
                            word1:'語',
                            word2:'言',
                        },
                        placeholder1:'請選擇地區',
                        placeholder2:'請選擇語言'
                    },
                    changePassword:{
                        step1:{
                            button1:"通過手機修改",
                            button2:"通過郵箱修改",
                            notice:"請聯繫管理員設置手機號或郵箱後再修改密碼"
                        },
                        step2:{
                            notice1:"為了您的帳號安全，需要驗證您的手機",
                            notice2:"為了您的帳號安全，需要驗證您的郵箱",
                            label1:"驗證碼",
                            placeholder1:"請輸入驗證碼",
                            button1:"獲取驗證碼",
                            button2:"重新發送"
                        },
                        step3:{
                            label1:"新密碼",
                            placeholder1:"請輸入新密碼",
                            label2:"確認密碼",
                            placeholder2:"請再次輸入新密碼",
                        },
                        buttons:{
                            ok:"確認",
                            cancel:"取消"
                        }
                    }
                }
            }
        },
        MoreIdea: {
            button: {
                cancel: '取消',
                ok: '完成',
                help:'使用指南',
                finishEdit: '完成替換',
                cancelEdit: '取消替換',
                moreIdea: '給我更多',
                replace: '替換原文',
                export:'下載為',
                wordCn:'Word（中文）',
                wordEn:'Word（英文）',
                freeMind:'FreeMind',
                markdown:'Markdown'
            },
            message:{
                error1:"請先選擇要Brainstorming的方案內容",
                error2:'至少選一個選項',
                error3:'至多選一個選項'
            },
            tour:{
                pre:'上一步',
                next:'下一步',
                finish:'我理解了'
            },
            tour1:{
                step1:'在這裡選擇想替換的內容',
                step2:'點擊獲取更多創意（至多5次）',
                step3:'在這裡查看更多創意',
                step4:'點擊翻頁',
                step5:'選擇創意並替換至原文對應的地方'
            },
            tour2:{
                step1:'艾迪已經把原本的主推薦創意幫您放入待Brainstorming區了，可以點擊“Brainstorming”來生成更多的創意。當看到您滿意的創意後，可以勾選出來，並點擊“替換原文”，然後它就會替換左側原方案中的對應部分'
            }
        },
        FullResearch:{
            placeholder1:'請告訴艾迪這次情報調研的內容。如，xxx產品將於下個月上市，請幫我做一下關於它和競品，行業和市場的調研簡報'
        }
    },
    components: {
        PageHeader: {},
        WaterfallPage: {
            button: {
                example: '參考範例',
                editBrief: '修改Brief',
                generatePlan: 'Brainstorming',
                finishEdit: '完成修改',
                editPlan: '更多創意',
                fullPlan:'完成方案',
                export:'下載為',
                wordCn:'Word（中文）',
                wordEn:'Word（英文）',
                freeMind:'FreeMind',
                markdown:'Markdown'
            },
            mode:{
                crazy:'--瘋狂創意模式'
            },
            input:{
                title:'本次ADGo的任務：'
            },
            brief: {
                title: 'Brief文件',
                viewSource: '查看資料來源',
                anchor: {
                    title: 'Brief'
                }
            },
            plan: {
                anchor: {
                    title1: 'Brainstorming',
                    title2: '完整方案'
                }
            },
            dialog: {
                content1: '請確認你的brief',
                content2: '需要開始生成方案嗎？',
                ok: '確認',
                cancel: '再想想'
            },
            complete:{
                message1:'艾迪已努力完成工作了！請您仔細閱讀。'
            },
            waiting:{
                message1:'艾迪正在思考中，您請先喝杯咖啡，稍等我{waitingTime}分鐘',
                message2:'（前方正在排隊，請稍後）'
            },
            error1:{
                message1:'很抱歉，艾迪不知道把方案放哪去了',
                message2:'（系統報錯，將為你重新生成）',
                redoTask:'重新生成'
            },
            error2:{
                message1:'很抱歉，艾迪生病回家了，請緊急聯繫他的老闆“老張”。',
                message2:'（系統報錯，請發送資訊：'
            },
            dialog2: {
                content1: '你確認要用當前主推薦創意來生成完整方案麼？',
                content2: '點擊“Brainstorming”按鈕獲得更多創意推薦',
                button1: 'Brainstorming',
                button2: '傳播計劃',
                button3: 'KOL計劃'
            },
            autoSave:'自動保存成功'
        },
        UsagePage:{
            ToolBar:{
                title:"用量情況",
                self:"本人",
                others:"其他成員"
            },
            chart:{
                title1:'月消耗',
                title2:'總消耗'
            },
            circle:{
                title:'消耗/剩餘'
            },
            BalanceTable:{
                title1:'獲取時間',
                title2:'狀態',
                title3:'餘額',
                title4:'過期時間',
                status1:'過期',
                status2:'可用'
            },
            TaskTable:{
                title:'實際使用情況',
                notice:{
                    content1:'代幣消耗說明',
                    content2:'方案',
                    content3:'翻譯',
                },
                title1:'功能',
                title2:'類型',
                title3:'次數'
            }
        }
    }
}

